.custom-card {
  border-width: 0px;
}
.card-body {
  padding: 0.3rem 0 0 0;
}

.custom-card-title {
  font: {
    family: "Lato";
    style: normal;
    weight: bold;
    size: 17px;
  }
  color: #262626;
  margin-bottom: 0.3rem;
  @include responisve-text(1);
}

.custom-card-text {
  font-family: "Lato";
  color: #262626bd;
  margin-bottom: 0.3rem;
  @include responisve-text(0.85);
}

.custom-card-sub-text {
  color: #26262666;
  margin-bottom: 0.3rem;
  @include responisve-text(0.85);
}

.custom-card-link-container{
  display: flex;
  flex-flow: row wrap;
}

.custom-card-link-text {
  color: #3d3d3d;
  @include responisve-text(1);
  display: block;
  margin: 10px 0;
  line-height: 100%;
  padding: 2px;
  text-decoration: underline;
  text-underline-offset: 2px;
  font: {
    family: "Lato";
    weight: 500;
  }

  &:hover {
    text-decoration: underline;
    color: #3d3d3d;
  }

  &::after {
    content: url("../resources/assets/tiltArrow-grey.svg");
    padding: 0 4px;
    height: 100%;
    width: auto;
  }
}

.card-button {
  color: #874388;
  padding: 1vh 8%;
  border: 2px solid #874388;
  border-radius: 4px;
  background-color: #fff;
  transform: scale(1, 1);
  transition: transform 0.5s, background-color 0.5s, color 0.5s;
  margin: 10px 0;
  display: inline-flex;
  white-space: nowrap;
  @include responisve-text(1);
  &:hover {
    background-color: #874388;
    transform: scale(1.1, 1.1);
    color: #fff;
  }
}
