.team-tabs {
  padding: 10px 30px;
}

.team-title {
  padding-bottom: 15px;
  text-emphasis: bold;
}

.nav-tabs {
  border-bottom: 1px solid;

  .nav-link {
    color: black;
    padding: 2px auto;
    font-family: "Red Hat Display";
    font-weight: 700;
  }

  .nav-link.active {
    color: blue;
    border-bottom-color: blue;
    border-bottom-width: 3px;
    border-top: none;
    border-left: none;
    border-right: none;
  }
}
