.what-we-do {
  height: 100vh;
  overflow: hidden;
  white-space: nowrap;
  transition: all 1s;
  @include media-breakpoint-down(lg) {
    margin-top: 5vh;
  }
  @include media-breakpoint-down(xs) {
    display: none;
  }

  &-wrapper {
    height: calc(100vh - 80px);
    margin: 80px 0 0;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-x-style: none;

    .fp-tableCell {
      vertical-align: top;
    }

    .mobile-div {
      height: 200vh;
      width: 80vw;

      @include media-breakpoint-down(md) {
        height: 150vh;
      }

      @include media-breakpoint-down(sm) {
        height: 100vh;
      }
    }

    .product-wrapper {
      position: absolute;
      width: 100vw;
      left: 0;
      bottom: 2vh;
      right: 0;
      z-index: 99;
    }
  }

  .mobile-div {
    .product-overlay {
      height: 100%;
      vertical-align: top;
    }
  }

  .first-carousel {
    .mobile-div {
      background-image: url("../resources/assets/mobile-bg.svg");
    }
  }

  &-info {
    max-width: 40vw;
    white-space: normal;
    margin: auto;
    padding-top: 5%;
    text-align: center;

    @include media-breakpoint-down(md) {
      max-width: 60vw;
    }

    @include media-breakpoint-down(sm) {
      max-width: 80vw;
    }
  }

  &-header {
    word-spacing: 3px;
    font-family: "Lato";
    font-weight: 900;
    @include responisve-text(1.5);
  }

  &-description {
    font-family: "Lato";
    @include responisve-text(1.1);
    font-style: normal;
    font-weight: normal;
    font-weight: 400;
    color: rgba(36, 36, 36, 0.6);
  }

  .projects-list-button-text {
    cursor: pointer;
    display: inline;
    color: #2b60f0;
    font-family: "Lato";
    font-weight: 600;
    &::after {
      content: url("../resources/assets/tiltArrow-blue.svg");
      padding: 5px;
    }
  }

  .apps-list,
  .libraries-list {
    vertical-align: top;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    transition: all 1s;
    transform: translateY(-60px);
  }
}

.toggle-products {
  &-content {
    cursor: pointer;
    position: relative;
    bottom: -10vh;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    transition: all 0.25s;
    @include media-breakpoint-down(sm) {
      bottom: -10vh;
    }
  }

  &-icon {
    padding: 5px 0;
    align-self: center;
  }
}

.product-wrapper-product {
  display: grid;
  overflow-x: scroll;
  grid-auto-flow: column;
  cursor: all-scroll;
  justify-content: flex-start;
  height: 17vh;
  align-items: center;
  transition: all 0.5s;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.product-wrapper {
  &.hidden {
    .product-wrapper-product {
      transform: translateY(110%);
      visibility: hidden;
      opacity: 0;
    }
  }

  &:not(.hidden) {
    .toggle-products-content {
      bottom: 2vh;
      @include media-breakpoint-down(sm) {
        bottom: 2vh;
      }
    }
  }
}
