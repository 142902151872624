#podcast {
  @include media-breakpoint-down(xs) {
    height: auto !important;

    .fp-tableCell {
      height: auto !important;
    }

    .podcast-section {
      margin-top: 30px;
    }
  }
}

.podcast-section {
  margin: 80px 7% 0 7%;
  height: calc(100% - 120px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;

  @include media-breakpoint-down(sm) {
    height: auto;
  }

  &-header {
    font: {
      family: "Lato";
      weight: 700;
    }
    @include responisve-text(1.5);
    margin: 0 0 3vh 0;
  }
  &-video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    &-container {
      width: 40%;
      padding-top: 25%;
      position: relative;
      overflow: hidden;
      @include media-breakpoint-down(md) {
        width: 60%;
        padding-top: 40%;
      }
      @include media-breakpoint-down(sm) {
        width: 80%;
        padding-top: 50%;
      }
    }
  }
  &-quote {
    &-header {
      font: {
        family: "Lato";
        weight: 700;
      }
      @include responisve-text(1);
      margin: 15px 0 0 0;
    }
    &-body {
      font: {
        family: "Lato";
        weight: 400;
      }
      max-width: 35vw;

      @include media-breakpoint-down(md) {
        max-width: none;
      }
      color: rgba(38, 38, 38, 0.74);
      margin: 2vh 0;
      @include responisve-text(1);
    }
  }
  &-button,
  &-meet-button {
    cursor: pointer;
    color: #7356bf;
    border: 2px solid #7356bf;
    border-radius: 4px;
    background-color: white;
    padding: 1vh 2%;
    transform: scale(1, 1);
    transition: transform 0.5s, background-color 0.5s, color 0.5s;
    @include responisve-text(1);

    &:hover {
      text-decoration: none;
      transform: scale(1.1, 1.1);
      background-color: #7356bf;
      color: white;
    }

    @include media-breakpoint-down(xs) {
      padding: 1vh 6%;
    }
  }

  &-button {
    color: #e66200;
    border-color: #e66200;

    &:hover {
      background-color: #e66200;
    }
  }

  &-meet-button {
    margin: 10px 0 50px 0;
  }
}
