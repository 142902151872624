@import "~bootstrap/scss/bootstrap.scss";
@import "mixins";
@import "card-container";
@import "custom-navbar";
@import "community-section";
@import "introduction";
@import "what-we-do";
@import "product-card";
@import "custom-footer";
@import "fonts";
@import "custom-card";
@import "news";
@import "custom-tabs";
@import "custom-card-layout";
@import "mdg-talks";
@import "podcast-section";
@import "scrolling-styles";
@import "project-column";
@import "project-carousel";
@import "mobile";
@import "member-card";
@import "teams-page";
@import "mobile-app-library";
@import "mobile-what-we-do";
@import "gallery";
@import "flickity";
@import "timeline";

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.lock-scroll {
  overflow: hidden;
  height: 100%;
}

@media screen and (min-width: 1200px) {
  body {
    margin-left: auto;
    margin-right: auto;
  }
}

#mobile-div {
  @include media-breakpoint-down(xs) {
    height: auto !important;
  }
}
