.mobile-div-container-mobile {
  .mobile-div {
    width: 20vw !important;
  }
}

.mobile-div {
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat;
  margin: auto;

  .mobile-overlay {
    width: 100%;
    height: 100%;
    object-fit: contain;

    .product-overlay {
      padding: 5vh 0 10vh 0;
      overflow: auto;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      text-align: center;
      margin: 7vh 10vh;

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      @include media-breakpoint-down(xs) {
        padding: 0;
        margin: 0vh;
      }

      &-heading {
        margin: 0;
        padding: 9vh 2vh;
        font-family: "Lato";
        font-weight: 600;
        font-size: 1.8rem;
        @include media-breakpoint-down(md) {
          font-size: 1.3rem;
        }
        @include media-breakpoint-down(sm) {
          font-size: 1rem;
        }
        color: #262626;
        @include media-breakpoint-down(xs) {
          position: absolute;
          width: 100%;
          font-size: 0.8rem;
          text-align: left;
          top: 6vh;
          left: 20px;
          padding: 0 0 0 0;
        }
      }
    }

    .app-wrapper {
      padding: 0 calc(2vh - 5px);
      top: 20vh;
      margin-bottom: 4vh;
    }

    .library-wrapper {
      padding: 0 calc(2vh - 5px);
      top: 45vh;
      margin-bottom: 4vh;
    }

    .library-heading {
      @include responisve-text(0.9);
      width: 100%;
      text-align: left;
      left: 20px;
      top: 42vh;
      position: absolute;
    }

    .app-heading {
      @include responisve-text(0.9);
      width: 100%;
      text-align: left;
      left: 20px;
      top: 10vh;
      position: absolute;
    }

    .product-wrapper-app {
      display: grid;
      grid-row-gap: 1vh;
      grid-column-gap: 2.5vh;
      overflow-x: scroll;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: column;
      margin-bottom: 1vh;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      cursor: all-scroll;
      grid-template-rows: auto auto;

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .mobile-app-library-wrapper {
        width: 70px;
      }
    }

    .product-wrapper-library {
      display: grid;
      overflow-x: scroll;
      grid-auto-flow: column;
      cursor: all-scroll;

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }
  }
}
