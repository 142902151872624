.parent-div-scroll {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
  scroll-padding: 80px;
  scroll-snap-stop: always;
  overflow-x: hidden;
}

.child-div-scroll {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
