.teams-page {
  &-header {
    margin: 100px 0 20px 0;
    font: {
      family: "Lato";
      color: black;
      weight: 700;
    }
    @include responisve-text(1.5);
    text-align: center;
  }
}
