.member-card {
  position: relative;
  aspect-ratio: 4/5;
  width: 100%;
  &:hover &-background {
    opacity: 1;
  }
  cursor: pointer;
  font: {
    family: "Lato";
    style: italic;
  }

  &-animate &-overlay {
    opacity: 1;
    height: 100%;
  }

  &-image {
    position: absolute;
    bottom: 0px;
    z-index: 2;
    height: 100%;
    min-height: 0;
  }

  &-background {
    position: absolute;
    bottom: -8%;
    left: -19%;
    z-index: 1;
    width: 132%;
    min-height: 0;
    opacity: 0;
    transition: all 0.5s;
  }

  &-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    background-color: rgba(36, 36, 36, 0.9);
    opacity: 1;
    transition: all 0.5s;

    &-desc {
      display: flex;
      flex-flow: column wrap;
      height: 100%;

      &-text {
        padding: 0 10px;
        margin: 0;
        color: white;
      }
      margin: auto;
    }

    &-data {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }

    &-cancel {
      width: 35px;
      height: 35px;
      padding: 10px;
      align-self: flex-end;
    }
  }

  &-div {
    width: 100%;
    height: 80%;
    position: absolute;
    bottom: 0px;
  }

  &-text {
    margin: 0;
    font: {
      weight: 700;
      style: "Lato";
    }
  }

  &-info {
    margin-top: 16px;
  }

  &-sub-text {
    margin: 0;
    font: {
      weight: 400;
      style: "Lato";
      color: #262626;
    }
    color: rgba(38, 38, 38, 0.4);
  }

  &-modal {
    .modal-content {
      background-color: transparent;
      border: 0px;
    }

    .modal-header {
      border: 0px;
    }

    color: white;
    @include responisve-text(1);
    font: {
      family: "Lato";
      style: italic;
    }

    &-body {
      display: flex;
      flex-flow: row nowrap;

      &-close-button {
        align-self: flex-start;
        cursor: pointer;
      }

      align-items: center;
    }

    &-content {
      display: flex;
      flex-flow: row nowrap;

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
      justify-content: center;
      align-items: center;
    }

    .small {
      display: none;
    }

    .large {
      display: block;
    }

    @include media-breakpoint-down(md) {
      .large {
        display: none;
      }

      .small {
        display: block;
      }
    }

    &-profile {
      &-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;

        .footer-social-media {
          margin: 0 8px;
        }

        padding: 20px 20px;
      }

      &-image {
        padding-bottom: 10px;
      }
    }

    &-info {
      &-container {
        padding: 20px 20px;
      }
    }
  }
}

.modal-backdrop {
  background-color: rgba(36, 36, 36, 0.95) !important;
  opacity: 1 !important;
  backdrop-filter: blur(2px);
}
