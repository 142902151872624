.podcast-header {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  height: 100vh;
  background-color: #e66200;

  &-text-container {
    max-width: 30%;
    @include media-breakpoint-down(lg) {
      max-width: 370px;
    }
    margin: 0% 7%;
  }

  &-title {
    color: white;
    font-weight: bold;
    @include responisve-text(1.75);
    font-family: "Lato";
    letter-spacing: 0.035em;
  }

  &-navigation-container {
    display: flex;
  }

  &-navigation-text {
    padding: 4px;
    color: white;
    @include responisve-text(1);
    font-family: "Lato";
    margin-bottom: 0px;
  }

  &-navigation-image {
    width: 10px;
    margin-left: 5px;
  }
}

.podcast-body {
  margin: 0% 7%;
  padding-top: 90px;

  .card-component {
    .card-body {
      padding: 0.75rem;
    }

    .card-text {
      margin-bottom: 0;
    }
  }
}

.podcast-intro {
  width: 40vw;
  padding: 0px 20px 0 0;
  @include media-breakpoint-between(sm, md) {
    align-self: center;
  }
  @include media-breakpoint-down(xs) {
    width: 100%;
    padding-bottom: 20px;
  }

  &-container {
    display: flex;
    margin: 40px 0px;
    justify-content: space-between;
    flex-flow: row nowrap;

    @include media-breakpoint-down(xs) {
      padding: 20px 0px;
      flex-wrap: wrap;
    }
  }

  &-title {
    font-family: "Lato";
    font-size: 2rem;
    font-weight: bold;
  }

  &-button {
    align-self: flex-start;
    background-color: white;
    color: #e66200;
    border: 2px solid #e66200;
    border-radius: 4px;
    padding: 2% 8%;
    transform: scale(1, 1);
    transition: color 0.5s, background-color 0.5s, transform 0.5s;

    &:hover {
      color: white;
      background-color: #e66200;
      transform: scale(1.1, 1.1);
    }
  }

  &-video {
    width: 45vh;
    @include media-breakpoint-between(sm, md) {
      width: calc(50% - 5px);
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
}
