.introduction {
  position: relative;
  padding: 0% 7%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  color: white;
  overflow: hidden;
  @include responisve-text(1);

  &-content {
    max-width: 45%;
    @include media-breakpoint-down(sm) {
      max-width: none;
    }
  }

  &-video,
  &-gif {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: -2;
    position: absolute;
    object-fit: cover;
  }

  &-overlay {
    height: 100vh;
    width: 150vw;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &-title {
    font-family: "Lato";
    letter-spacing: 0.035em;
  }

  &-header {
    font-family: "Lato";
    font-weight: bold;
    @include media-breakpoint-down(xs) {
      font-size: 1.5rem;
    }
    @include responisve-text(1.75);
    letter-spacing: 0.035em;
  }

  &-description {
    font-family: "Lato";
    font-style: normal;
    letter-spacing: 0.035em;
  }

  .introduction-chat-button {
    color: white;
    border: 2px solid white;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 1.5vh 8%;
    transform: scale(1, 1);
    transition: transform 0.5s;

    &:hover {
      text-decoration: none;
      transform: scale(1.1, 1.1);
    }
  }
}
