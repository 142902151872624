.footer-container {
  padding: 4% 7%;
  background-color: #fbfbfb;
}

.footer-social-link {
  display: flex;
  flex-flow: row nowrap;
}

.footer-social-media {
  margin-right: 15px;
  transform: scale(1, 1);
  transition: transform 0.2s ease-out;

  &:hover {
    transform: scale(1.3, 1.3);
  }
}

.footer-contact {
  display: flex;
  flex-flow: column nowrap;
  max-width: 300px;
  margin-bottom: 8px;

  @media screen and (max-width: 1062px) {
    margin-bottom: 40px;
  }
}

.footer-contact-handles > * {
  margin: 0;
}

.footer-chat-anchor:hover {
  text-decoration: none;
}

.footer-contact-chat {
  margin-top: 8px;
  color: rgba(61, 61, 61, 1);

  > .footer-chat-text {
    margin-right: 8px;
  }
}

.footer-intro {
  display: flex;
  flex-flow: column nowrap;
  max-width: 600px;
  margin-bottom: 8px;

  @media screen and (max-width: 1062px) {
    margin-bottom: 40px;
  }
}

.custom-footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  color: rgba(38, 38, 38, 0.74);
}

.footer-copyright {
  margin: 0;
}

#email:target {
  background: blue;
  color: white;
}
