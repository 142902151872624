#project-list-col {
  margin-top: 30px;
}

.project-list-entity {
  padding-top: 5px;
  white-space: nowrap;
  > a {
    font-family: "Lato";
    color: rgba(38, 38, 38, 0.74);
    @include responisve-text(1.1);
    transition: all;

    &:hover {
      font-weight: 600;
      color: black;
      text-decoration: none;
    }
  }
}
