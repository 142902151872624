.product-container {
  vertical-align: top;
  display: flex;
  text-align: center;
  width: 19vh;
  height: 15.2vh;
  // margin-right: 10px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  // background-color: rgba(252, 193, 21, 1);
  margin: 0 10px;
  border-radius: 10px;
  align-content: space-around;
  justify-content: space-evenly;
  flex-direction: column;
  white-space: normal;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1, 1.1);
  }

  img {
    margin-bottom: 8px;
    object-fit: cover;
    height: 7vh;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    margin-top: 1vh;
    align-self: center;
  }

  span {
    display: block;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6vh;
    text-align: center;
    white-space: normal;
  }

  &-active {
    transform: scale(1.1, 1.1);
    overflow: visible !important;
    border: 2px solid rgba(0, 0, 0, 0.4);
  }

  &.first-project{
    background-image: url('../resources/assets/main-project.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-color: white;
    width: 22vh;
  }
}
