.community-section {
  margin: 80px 7% 0 7%;
  //   height: 100%;
  &-header {
    text-align: center;
    padding: 0 0 1rem 0;
    font: {
      family: "Lato";
      size: 20px;
      weight: 700;
    }
    margin: 0;
    @include responisve-text(1.5);
  }
}
