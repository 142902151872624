.news-link:hover {
  text-decoration: none;
}

.news-title-container {
  font-weight: 600;
  color: #262626;
  padding: 16px 0 4px 0;
  display: inline-block;
}

.news-title {
  margin-right: 8px;
}

.news-date {
  padding: 4px 0 16px 0;
  color: #262626;
  opacity: 0.4;
}

.news-link-img {
  height: 10px;
}
