.gallery {
  &-title {
    text-align: center;
    padding: 20px;
    font: {
      weight: 600;
      family: "Lato";
    }
    @include responisve-text(1.2);
  }

  &-image {
    margin: 0 5px;
    height: 100%;
    width: auto;

    &-wrapper {
      width: auto;
      height: 20vw;
      @include media-breakpoint-down(sm) {
        height: 50vw;
      }
    }

    &-container {
      display: grid;
      grid-auto-flow: column;
      overflow-x: scroll;
      cursor: all-scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &-wrapper {
    width: 100vw;
    overflow: auto;
    margin: 0 5px;
    z-index: 99;
  }

  &-description {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    &-text {
      text-align: center;
      color: rgba(38, 38, 38, 0.74);
      overflow: visible;
      margin-top: 20px;
      padding: 10px 0;
      font: {
        style: "Lato";
        weight: 600;
      }
      @include responisve-text(0.9);
    }
  }
}
