.flickity-wrapper {
  margin: 0 17vw;
  width: 66vw;
  overflow: visible;
}

.timeline-heading {
  margin: 80px 0 30px 0;
  @include responisve-text(1.5);
  text-align: center;
  font: {
    weight: 700;
    family: "Lato";
  }
}

.timeline-flickity {
  .flickity-viewport {
    overflow-x: hidden;
    overflow-y: hidden;

    .timeline-event {
      display: flex;
      padding: 15px;
      margin: 0 10px;
      //height: 32vw;
      flex-flow: column nowrap;
      //   @include media-breakpoint-down(lg) {
      //     height: 20vw;
      //   }

      //   @include media-breakpoint-down(md) {
      //     height: 32vw;
      //   }

      //   @include media-breakpoint-down(xs) {
      //     height: 61vw;
      //   }
      .timeline-event {
        &-title {
          line-height: 1.5em;
          height: calc(3em + 10px);
          font: {
            weight: bold;
            family: "Lato";
          }
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
          color: rgba(38, 38, 38, 1);
          @include responisve-text(1.1);

          &::after {
            content: url("../resources/assets/tiltArrow-black.svg");
            padding: 10px 4px;
          }
        }

        &-date {
          color: rgba(38, 38, 38, 0.4);
        }
      }

      &-title {
        padding: 10px 0 0 0;
      }

      .timeline-image {
        height: 100%;
      }

      .timeline-image-container {
        height: 17vw;
        width: auto;
        overflow: hidden;
        object-fit: cover;
        // transition: all 0.5s;

        @include media-breakpoint-down(lg) {
          height: 18vw;
        }

        @include media-breakpoint-down(md) {
          height: 30vw;
        }

        @include media-breakpoint-down(xs) {
          height: 59vw;
        }
      }
    }

    .is-selected {
      .timeline-image-container {
        transform: scale(1.1, 1.1);
        transition: all 0.5s;
      }

      .timeline-event {
        &-title {
          margin-top: 1.25vw;
          transform: translateX(-1.25vw);
          //transition: all 0.5s;

          @include media-breakpoint-down(lg) {
            margin-top: 0.9vw;
            transform: translateX(-0.9vw);
          }

          @include media-breakpoint-down(md) {
            margin-top: 1.5vw;
            transform: translateX(-1.5vw);
          }

          @include media-breakpoint-down(xs) {
            margin-top: 2.95vw;
            transform: translateX(-2.95vw);
          }
        }

        &-date {
          transform: translateX(-1.25vw);

          @include media-breakpoint-down(lg) {
            transform: translateX(-0.9vw);
          }

          @include media-breakpoint-down(md) {
            transform: translateX(-1.5vw);
          }

          @include media-breakpoint-down(xs) {
            transform: translateX(-2.95vw);
          }
        }
      }
    }
  }

  .flickity-prev-next-button.previous {
    left: -5vw;

    @include media-breakpoint-down(md) {
      left: -10vw;
    }
  }
  .flickity-prev-next-button.next {
    right: -5vw;

    @include media-breakpoint-down(md) {
      right: -10vw;
    }
  }
}

.timeline-bar {
  margin: 100px auto;
  width: 66vw;
  height: 2px;
  background-color: black;

  .timeline-bar-items {
    margin-top: -6px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .timeline-bar-item {
    position: absolute;
    cursor: pointer;
    transition: all 2s;

    &-current {
      transform: scale(1.5, 1.5);
      .timeline-circle {
        background-color: black;
      }
      cursor: default;
    }
  }

  .timeline-bar-item-active {
    position: absolute;

    .timeline-bar-image {
      top: -2.5vw;
      width: 3vw;
      height: 3vw;
      transition: all 0.3s;

      @include media-breakpoint-down(xl) {
        top: -40px;
        width: 60px;
        height: 60px;
      }
    }

    .timeline-circle {
      transform: scale(1.4, 1.4);
      transition: all 0.3s;
    }
  }

  .timeline-bar-image {
    position: absolute;
    top: -1.5vw;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 10px;
    width: 2.5vw;
    height: 2.5vw;
    object-fit: contain;

    @include media-breakpoint-down(xl) {
      top: -30px;
      width: 50px;
      height: 50px;
    }
  }

  .timeline-circle {
    height: 0.95vw;
    width: 0.95vw;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: -0.38vw;
    left: -0.38vw;

    @include media-breakpoint-down(lg) {
      height: 10px;
      width: 10px;
      top: -4px;
      left: -4px;
    }
  }

  .timeline-years-list {
    position: relative;
    margin: 10px 0;

    .timeline-year {
      position: absolute;
      font: {
        size: 1.2vw;
      }
      transform: translateX(-50%);

      @include media-breakpoint-down(md) {
        font-size: 2vw;
      }

      @include media-breakpoint-down(xs) {
        font-size: 4vw;
      }
    }

    @include media-breakpoint-down(xs) {
      .timeline-year:nth-child(even) {
        display: none;
      }
    }
  }
}
