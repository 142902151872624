.navbar {
  width: 100vw;
  padding: 10px 20px;
  transition: all 0.2s;
  padding-left: 50px;

  @include media-breakpoint-down(md) {
    padding-left: 20px;
  }

  &.navbar-expand-lg .navbar-nav .nav-link {
    font-family: "Lato";
    margin-right: 20px;
    margin-left: 20px;
    font-weight: 400;
  }

  &.scrollDown {
    top: -80px;

    &.elevated {
      box-shadow: none;
    }
  }

  &.elevated {
    box-shadow: none;
  }

  .navbar-toggler {
    padding: 4px;
    border: 0;

    &.collapsed {
      .top-bar {
        transform: rotate(0);
      }
      .middle-bar {
        transform: rotate(0);
      }
      .bottom-bar {
        opacity: 1;
      }
    }
  }

  .navlink-blog::after {
    content: "";
    background-image: url("../resources/assets/tiltArrow-white.svg");
    background-size:  80%;
    background-repeat: no-repeat;
    display: inline-block;
    height: 55%;
    padding: 0 8px;
    margin-left: 6px;
  }
}

.navbar-nav {
  @include media-breakpoint-down(md) {
    justify-content: space-evenly;
    align-items: center;
    height: calc(100vh - 80px);
  }
}

.custom-nav-bar {
  &-normal {
    --background-color: transparent;

    background-color: var(--background-color);
    transition: all 0.1s;
    &.elevated {
      --background-color: white;
      .navbar-nav {
        .nav-link {
          font-family: "Lato";
          color: rgba($color: #262626, $alpha: 0.74);
          font-weight: 400;

          &:hover {
            color: black;
          }

          &:focus {
            color: rgba($color: #262626, $alpha: 0.74);
          }
        }

        .nav-link.active {
          color: black;
          transform: scale(1.2, 1.2);
        }

        .navlink-blog::after {
          content: "";
          background-image: url("../resources/assets/tiltArrow.svg");
          background-size:  80%;
          background-repeat: no-repeat;
          display: inline-block;
          height: 55%;
          padding: -3px 6px;
        }
      }

      .icon-bar {
        background-color: #677b8c;
      }
    }

    .navbar-nav {
      .nav-link {
        transition: color 1s;
        color: white;

        &:hover {
          transform: scale(1.2, 1.2);
          color: white;
          transition: all 0.5s;
        }

        &:focus {
          color: white;
        }
      }

      .nav-link.active {
        color: white;
        transform: scale(1.2, 1.2);
      }
    }

    .icon-bar {
      background-color: white;
    }
  }
}

.logo-design {
  width: 70px;
  height: 50px;
}

.icon-bar {
  height: 2px;
  background-color: grey;
  display: block;
  margin: 5px 0;
  transition: all 0.2s;

  &.top-bar {
    width: 24px;
    transform: translateY(7.5px) rotate(45deg);
  }

  &.middle-bar {
    width: 24px;
    transform: rotate(-45deg);
  }

  &.bottom-bar {
    width: 16px;
    opacity: 0;
  }
}
