.app-container {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  margin: 0 10px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  img {
    height: 8vh;
    margin-bottom: 8px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  span {
    display: block;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vh;
    text-align: center;
    color: black;
    white-space: break-spaces;
  }
}

.library-container {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  img {
    object-fit: contain;
    height: 16vh;
    width: 13vh;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border-radius: 10px;
  }

  span {
    display: block;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vh;
    text-align: center;
    color: black;
    white-space: break-spaces;
  }
}

.mobile-app-library-wrapper {
  position: static;
}

.product-wrapper-wrapper {
  position: relative;
}
