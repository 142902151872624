.card-container {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-between;
}

.card-component {
  width: calc(33% - 40px);

  @include media-breakpoint-between(sm, md) {
    width: calc(50% - 40px);
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
    margin-bottom: 5vh;
  }
}
