.project-body {
  margin-top: 100px;

  .project-description {
    text-align: center;

    .project-header {
      margin: auto;
    }

    .project-info {
      margin: auto;
    }

    .project-contribute-link {
      margin: 0 auto 16px;
    }

    .get-project-button {
      margin: auto;
    }
  }

  .product-wrapper {
    margin-top: 5vh;
  }

  .product-container {
    display: inline-block;
  }

  .fp-tableCell {
    vertical-align: top;
    height: auto !important;
  }
}

.project-carousel {
  .project {
    &-item {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    padding: 0 7%;

    &-overview {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin: 3% auto 0;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
      }

      .mobile-div {
        flex-shrink: 0;
        height: 60vh;
        width: 40vw;

        @include media-breakpoint-down(md) {
          height: 45vh;
          width: 80vw;
        }
      }
    }

    &-description {
      flex-shrink: 1;
      white-space: normal;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 40vw;
      overflow: visible;
      margin: 0 50px 0;
      align-self: stretch;

      @include media-breakpoint-down(md) {
        max-width: 100%;
        margin: 10px 0;
      }
    }

    &-description-mobile {
      margin: 0 100px;
    }

    &-description-mobile-landscape {
      margin: 0 80px 0;
    }

    &-info {
      display: flex;
      align-items: center;
      padding: 5px 0px;
      color: rgba(38, 38, 38, 1);
      font-family: "Lato";
      font-weight: 600;
      @include media-breakpoint-down(md) {
        margin-top: 20px;
      }

      @include media-breakpoint-down(xs) {
        img {
          width: 10vw;
        }
      }
    }

    &-name {
      font-family: "Lato";
      padding: 0px 10px;
      margin: 0;
      @include responisve-text(1);
    }

    &-header {
      font-family: "Lato";
      @include responisve-text(1.5);
      font-weight: 600;
    }

    &-detail {
      font-family: "Lato";
      overflow-wrap: break-word;
      word-wrap: break-word;
      @include responisve-text(1);
    }

    &-contribute-link {
      color: rgba(38, 38, 38, 0.74);
      font: {
        family: "Lato";
        weight: 600;
      }
      overflow-wrap: break-word;
      word-wrap: break-word;
      @include responisve-text(1);
      margin: 0 0 16px 0;
      text-decoration: none;
      line-height: 100%;

      &:hover {
        text-decoration: none;
      }

      &::after {
        content: url(../resources/assets/tiltArrow.svg);
        vertical-align: middle;
        margin: 0 4px;
      }
    }

    .control-next {
      align-self: center;
    }

    .control-prev {
      align-self: center;
    }
  }

  .news-section .icons-container {
    display: flex;
    .icon {
      padding: 0px 10px 0px 0px;
    }
  }

  .get-project-button {
    border-radius: 4px;
    background-color: white;
    padding: 1vh 3%;
    transition: transform 0.5s, background-color 0.5s, color 0.5s, border 0.5s;
    @include responisve-text(1);
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;

  * {
    position: absolute;
    top: 35vh;
    height: 30px;
  }

  @include media-breakpoint-down(xs) {
    display: none;
  }
}
