.card-layout {
  margin: 0 30px 80px 30px;
  display: flex;
  flex-flow: row wrap;

  .member-card-container {
    display: flex;
    flex-flow: column nowrap;
    width: calc(25% - 60px);
    margin: 30px;

    @include media-breakpoint-down(md) {
      width: calc(33% - 60px);
    }

    @include media-breakpoint-down(sm) {
      width: calc(50% - 60px);
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
      margin: 50px 30px;
    }
  }
}
