.mobile-what-we-do {
  height: auto;
  overflow: visible;
  white-space: nowrap;
  transition: all 1s;
  display: none;
  @include media-breakpoint-down(lg) {
    margin-top: 5vh;
  }
  @include media-breakpoint-down(xs) {
    display: block;
  }

  &-wrapper {
    padding: 0 7% 0 7%;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-x-style: none;
  }

  .mobile-div {
    height: 70vh;
    width: 35vh;
    background-image: url("../resources/assets/mobile-bg.svg");

    .product-overlay {
      overflow: hidden;
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      position: relative;
    }
  }

  &-info {
    max-width: 30vw;
    margin: 0 calc(35vw - 17.5vh - 150px) 0 150px;
    white-space: normal;
  }

  &-header {
    word-spacing: 3px;
    font-family: "Lato";
    font-weight: 900;
    @include responisve-text(1.5);
  }

  &-description {
    font-family: "Lato";
    @include responisve-text(1.1);
    font-style: normal;
    font-weight: normal;
    font-weight: 400;
    color: rgba(36, 36, 36, 0.6);
  }

  .projects-list-button-text {
    cursor: pointer;
    display: inline;
    color: #2b60f0;
    font-family: "Lato";
    font-weight: 600;
    &::after {
      content: url("../resources/assets/tiltArrow-blue.svg");
      padding: 5px;
    }
  }

  .get-project-button {
    text-decoration: none;
  }

  .apps-list,
  .libraries-list {
    vertical-align: top;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    transition: all 1s;
    transform: translateY(-60px);
  }
}
